import { WEBSITE_LABEL_FULL } from './global';
import { DOCS_ADDRESS, REPO_ADDRESS } from './links';

const config = {
  title: WEBSITE_LABEL_FULL,
  subtitle:
    'Open source software that standardizes the process of connecting to and participating in real-time payment networks for all types and sizes of financial institutions.',
  buttons: [
    {
      label: 'Get Started',
      href: DOCS_ADDRESS,
      className: 'button is-primary',
    },
    {
      label: 'GitHub',
      href: REPO_ADDRESS,
      className: 'button is-primary is-outlined',
    },
  ],
  sectionOne: {
    title: 'Connect easily to real-time payment networks',
    description:
      'The simplest way to establish a connection between your banking core and real-time payment networks like Mojaloop without requiring a high level of sophistication and skill. ',
    subtitle: 'Participate confidently with standardized...',
    items: [
      {
        title: 'Banking core connections',
        href: '#',
      },
      {
        title: 'Security best practices',
        href: '#',
      },
      {
        title: 'Fraud control and validation',
        href: '#',
      },
      {
        title: 'Transaction monitoring and tracking',
        href: '#',
      },
    ],
  },
  sectionTwo: {
    title: 'Featured Project',
    subtitle: 'Payment Manager OSS',
    description:
      'Mojaloop is the world’s first open source platform for interoperability in a real-time payment network. Payment Manager OSS enables financial institutions to easily connect and participate in a Mojaloop payment hub with standardized banking core connections, security best practices, fraud monitoring, tracking, and more.',
    button: {
      label: 'Learn More',
      href: '/payment-manager-oss/',
    },
  },
  sectionFour: {
    rowOne: {
      title: 'Is open source software safe for the financial industry?',
      text: `Over the last decade, open source software solutions have played an increasingly vital role in the financial industry, leveling the playing field between small and large FIs. Open source solutions empower smaller FIs with the resources and technical capabilities to keep pace with today’s rapidly evolving technologies, regulatory requirements, and customer demand for new services. As more FIs are included, big banks and their customers also benefit from a growing ecosystem of participants. Additionally, regulators around the world are introducing new policies that promote transparency throughout the financial industry, paving the way for more open source technologies.`,
    },
    rowTwo: {
      title: 'What is enterprise open source?',
      text: `Payment Manager OSS is an enterprise open source solution. The code is fully open and available to download, host in the cloud or on-premise, and change or distribute how you see fit. Most importantly, a team of maintainers and contributors stand behind the product to conduct ongoing testing, performance tuning, and evaluating and fixing security vulnerabilities. And there are available support agreements if needed.`,
    },
    rowThree: {
      title: 'What others say about enterprise OSS',
      links: [
        {
          label: 'RedHat: What is enterprise open source?',
          href: 'https://www.redhat.com/en/blog/what-enterprise-open-source',
        },
        {
          label: 'FINOS, Fintech Open Source Foundation',
          href: 'https://www.finos.org/',
        },
      ],
    },
  },
};

export default config;
