import React from 'react';
import './index.scss';

import { REPO_ADDRESS } from '../../data/links';
import { WEBSITE_LABEL } from '../../data/global';
import data from '../../data/homePage';

import Layout from '../../components/Layout';
import Logo from '../../components/Logo';
import PaymentManagerLogo from '../../components/PaymentManagerLogo';
import * as Assets from '../../assets';

const sectionOneIcons = [
  <Assets.ApiDesigner />,
  <Assets.LockShield />,
  <Assets.AccessManagement />,
  <Assets.BusinessView />
];

function renderSectionOneItem({ title }: { title: string; href?: string }, index: number) {
  return (
    <div key={index.toString()} className="column center">
      <div className="section-one__item">
        <figure className="image is-spaced-bottom is-center">
          {sectionOneIcons[index]}
        </figure>
        <span className="title is-size-6-desktop is-size-5-mobile has-text-weight-semibold">
          {title}
        </span>
      </div>
    </div>
  )
}

// markup
export default function IndexPage() {
  return (
    <Layout>
      <section className="hero is-light">
        <div className="hero-body showcase-container">
          <div className="container has-text-centered">
            <figure className="image is-spaced-bottom">
              <Logo className="logo-image" width={98} height={98} />
            </figure>
            <div className="title-section">
              <span className="title is-size-2-desktop is-size-3-mobile has-text-weight-semibold is-spaced">
                {data.title}
              </span>
              <span className="subtitle is-size-4-desktop is-size-5-mobile mw-500 main-subtitle">
                {data.subtitle}
              </span>
            </div>
            <div className="button-group">
              <div className="columns is-desktop">
                {data.buttons.map(({ className, label, href }, index) => (
                  <div className="column" key={index.toString()}>
                    <a className={className} href={href}>
                      {label}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="content-wrapper container is-max-desktop full-width">
        <section className="section-one">
          <div className="container">
            <div className="columns is-centered section-one__columns-custom">
              <div className="column center is-one-quarter">
                <figure className="image is-spaced-bottom">
                  <Assets.LaunchIcon />
                </figure>
              </div>
              <div className="column content-column">
                <span className="title">{data.sectionOne.title}</span>
                <p className="is-size-6-desktop is-size-6-mobile content-text is-spaced-top">{data.sectionOne.description}</p>
              </div>
            </div>
            <div className="is-spaced">
              <span className="title is-size-3-desktop is-size-4-mobile">{data.sectionOne.subtitle}</span>
            </div>
            <div className="columns is-centered m-b-60 full-width">
              {data.sectionOne.items.slice(0, 4).map(renderSectionOneItem)}
            </div>
          </div>
        </section>
        <section className="section-two">
          <div className="container">
            <div className="columns m-b-60 reverse-order">
              <div className="column">
                <span className="title">
                  {data.sectionTwo.title}
                </span>
                <div className="separator" />
                <span className="subtitle is-size-3-desktop">
                  {data.sectionTwo.subtitle}
                </span>
                <p className="is-size-6-desktop is-size-6-mobile content-text is-spaced-top">{data.sectionTwo.description}</p>
                <a className="button is-primary is-outlined" href={data.sectionTwo.button.href}>
                  {data.sectionTwo.button.label}
                </a>
              </div>
              <div className="column center is-one-third">
                <figure className="image center">
                  <PaymentManagerLogo width={98} height={98}/>
                </figure>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container center section-three">
            <div className="columns m-b-60 reverse-order">
              <div className="column">
                <div className="row is-spaced-top">
                  {/* Section three - row One*/}
                  <span className="title is-size-3-desktop is-size-4-mobile is-spaced">
                    {data.sectionFour.rowOne.title}
                  </span>
                  <p className="is-size-6-desktop is-size-7-mobile content-text">
                    {data.sectionFour.rowOne.text}
                  </p>
                </div>
                <div className="row is-spaced-top">
                  {/* Section three - row One*/}
                  <span className="title is-size-3-desktop is-size-4-mobile is-spaced">
                    {data.sectionFour.rowTwo.title}
                  </span>
                  <p className="is-size-6-desktop is-size-7-mobile content-text">
                    {data.sectionFour.rowTwo.text}
                  </p>
                </div>
                <div className="row is-spaced-top">
                  {/* Section three - row Two*/}
                  <span className="title is-size-5-desktop is-size-6-mobile is-spaced">
                    {data.sectionFour.rowThree.title}
                  </span>
                  <ul className="links__wrapper">
                    {data.sectionFour.rowThree.links.map((link, index) => (
                      <li key={index.toString()}>
                        <a href={link.href}>{`${link.label}`}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="column is-one-third">
                <figure className="image is-spaced-top center">
                  <Assets.OSSIcon />
                </figure>
              </div>
            </div>
          </div>
        </section>
        <div className="container has-text-centered">
          <p className="is-size-3-desktop is-size-5-mobile">{`Start Using ${WEBSITE_LABEL} Now!`}</p>
          <div className="button-group">
            <a className="button is-primary" href={REPO_ADDRESS}>
              GitHub
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}
